//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, Ellipsis, XCard } from '@/components'
import { tenantPage, tenantDelete } from '@/api/modular/tenant/tenantInfoManage'
import addForm from './addForm'
import editForm from './editForm'
export default {
  components: {
    XCard,
    STable,
    Ellipsis,
    addForm,
    editForm
  },
  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '租戶名稱',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '租戶編碼',
          dataIndex: 'code',
          scopedSlots: { customRender: 'code' }
        },
        {
          title: '關聯的數據庫',
          dataIndex: 'dbName'
        },
        {
          title: '創建時間',
          dataIndex: 'createTime'
        }
      ],
      loadData: parameter => {
        return tenantPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      Loading: false,
      jdbcDriverList: []
    }
  },
  created () {
    if (this.hasPerm('tenantInfo:edit') || this.hasPerm('tenantInfo:delete')) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    tenantDelete (record) {
      this.Loading = true
      tenantDelete(record).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      }).finally((res) => {
        this.Loading = false
      })
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
