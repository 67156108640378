/**
 * 租戶表控制
 *
 * @author yubaoshan
 * @date 2020/9/4 13:29
 */
import { axios } from '@/utils/request'

/**
 * 租戶分頁列表
 *
 * @author yubaoshan
 * @date 2020/9/4 13:31
 */
export function tenantPage (parameter) {
  return axios({
    url: '/tenantInfo/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 增加租戶
 *
 * @author yubaoshan
 * @date 2020/9/4 13:31
 */
export function tenantAdd (parameter) {
  return axios({
    url: '/tenantInfo/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 編輯租戶
 *
 * @author yubaoshan
 * @date 2020/9/4 13:31
 */
export function tenantEdit (parameter) {
  return axios({
    url: '/tenantInfo/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 刪除租戶
 *
 * @author yubaoshan
 * @date 2020/9/4 13:31
 */
export function tenantDelete (parameter) {
  return axios({
    url: '/tenantInfo/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 查看詳情接口
 *
 * @author yubaoshan
 * @date 2020/9/4 13:31
 */
export function tenantDetail (parameter) {
  return axios({
    url: '/tenantInfo/detail',
    method: 'get',
    params: parameter
  })
}

/**
 * 租戶列表
 *
 * @author yubaoshan
 * @date 2020/9/4 13:31
 */
export function tenantListTenants (parameter) {
  return axios({
    url: '/tenantInfo/listTenants',
    method: 'get',
    params: parameter
  })
}
